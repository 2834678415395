import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Component as Textarea } from './Textarea';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "textarea"
    }}>{`Textarea`}</h1>
    <p>{`문자열을 입력받는 컴포넌트입니다.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Textarea} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<Textarea placeholder=\"입력해주세요.\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Textarea,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Textarea placeholder="입력해주세요." mdxType="Textarea" />
    </Playground>
    <h2 {...{
      "id": "inline-textareas"
    }}>{`Inline textareas`}</h2>
    <Playground __position={2} __code={'<Textarea inline placeholder=\"입력해주세요.\" />\n<Textarea inline placeholder=\"입력해주세요.\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Textarea,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Textarea inline placeholder="입력해주세요." mdxType="Textarea" />
  <Textarea inline placeholder="입력해주세요." mdxType="Textarea" />
    </Playground>
    <h2 {...{
      "id": "with-allow-message"
    }}>{`With allow message`}</h2>
    <Playground __position={3} __code={'<Textarea allowMessage=\"올바른 값입니다.\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Textarea,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Textarea allowMessage="올바른 값입니다." mdxType="Textarea" />
    </Playground>
    <h2 {...{
      "id": "with-error-message"
    }}>{`With error message`}</h2>
    <Playground __position={4} __code={'<Textarea errorMessage=\"특수문자를 제외하고 입력해주세요.\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Textarea,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Textarea errorMessage="특수문자를 제외하고 입력해주세요." mdxType="Textarea" />
    </Playground>
    <h2 {...{
      "id": "with-warn-message"
    }}>{`With warn message`}</h2>
    <Playground __position={5} __code={'<Textarea warnMessage=\"보안에 취약한 값입니다.\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Textarea,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Textarea warnMessage="보안에 취약한 값입니다." mdxType="Textarea" />
    </Playground>
    <h2 {...{
      "id": "disabled-textarea"
    }}>{`Disabled textarea`}</h2>
    <Playground __position={6} __code={'<Textarea placeholder=\"잠금 상태입니다.\" disabled />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Textarea,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Textarea placeholder="잠금 상태입니다." disabled mdxType="Textarea" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      